@font-face {
    font-family: sf pro display;
    font-style: normal;
    font-weight: 400;
    src: local('SF Pro Display'), url(https://fonts.cdnfonts.com/s/59278/SFPRODISPLAYREGULAR.woff) format('woff')
}

@font-face {
    font-family: sf pro display;
    font-style: italic;
    font-weight: 100;
    src: local('SF Pro Display'), url(https://fonts.cdnfonts.com/s/59278/SFPRODISPLAYULTRALIGHTITALIC.woff) format('woff')
}

@font-face {
    font-family: sf pro display;
    font-style: italic;
    font-weight: 200;
    src: local('SF Pro Display'), url(https://fonts.cdnfonts.com/s/59278/SFPRODISPLAYTHINITALIC.woff) format('woff')
}

@font-face {
    font-family: sf pro display;
    font-style: italic;
    font-weight: 300;
    src: local('SF Pro Display'), url(https://fonts.cdnfonts.com/s/59278/SFPRODISPLAYLIGHTITALIC.woff) format('woff')
}

@font-face {
    font-family: sf pro display;
    font-style: normal;
    font-weight: 500;
    src: local('SF Pro Display'), url(https://fonts.cdnfonts.com/s/59278/SFPRODISPLAYMEDIUM.woff) format('woff')
}

@font-face {
    font-family: sf pro display;
    font-style: italic;
    font-weight: 600;
    src: local('SF Pro Display'), url(https://fonts.cdnfonts.com/s/59278/SFPRODISPLAYSEMIBOLDITALIC.woff) format('woff')
}

@font-face {
    font-family: sf pro display;
    font-style: normal;
    font-weight: 700;
    src: local('SF Pro Display'), url(https://fonts.cdnfonts.com/s/59278/SFPRODISPLAYBOLD.woff) format('woff')
}

@font-face {
    font-family: sf pro display;
    font-style: italic;
    font-weight: 800;
    src: local('SF Pro Display'), url(https://fonts.cdnfonts.com/s/59278/SFPRODISPLAYHEAVYITALIC.woff) format('woff')
}

@font-face {
    font-family: sf pro display;
    font-style: italic;
    font-weight: 900;
    src: local('SF Pro Display'), url(https://fonts.cdnfonts.com/s/59278/SFPRODISPLAYBLACKITALIC.woff) format('woff')
}