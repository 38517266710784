.App {
    text-align: center;
    height: 100%;
    min-height: 100%;
    padding-left: 8%;
    padding-right: 8%;
    padding-bottom: 8%;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Login {
    background-image: url('../public/login-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.Login-header {
    margin-top: 50%;
}

.App-header-button {
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.App-header-title {
    width: 100%;
    text-align: center;
    font-family: 'SF Pro Display', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 28px;
    letter-spacing: 0.35px;
    color: #92009D;
}

.Button {
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    /*display: -webkit-flex;*/
    /*display: flex;*/
    /*justify-content: end;*/
    justify-content: center;
    text-align: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    /*line-height: 60px;*/
    color: #1F3C75;
    /*background: none;*/
    border: none;

    width: 100%;
    height: 60px;
    /*margin-bottom: 175px;*/
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 75px 75px rgba(69, 42, 124, 0.15);
    backdrop-filter: blur(75px);
    border-radius: 15px;
    margin-bottom: 10%;
}

.NavButton {
    img {
        width: 30px;
        height: 30px;
    }
}

.SubmitButton {
    justify-content: center;
    text-align: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #1F3C75;
    border: none;
    width: 60%;
    height: 60px;
    border-radius: 30px;
    margin-bottom: 10%;
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0px 75px 75px rgba(69, 42, 124, 0.15);
    backdrop-filter: blur(30px);
}

/*img {*/
/*    width: 30px;*/
/*    height: 30px;*/
/*}*/

.Button-extended {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 20px;
    /*background: none;*/
    border: none;
    width: 100%;
    height: 60px;
    /*margin-bottom: 175px;*/
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 75px 75px rgba(69, 42, 124, 0.15);
    backdrop-filter: blur(75px);
    border-radius: 15px;
    margin-bottom: 10%;
}

.ButtonTitle {
    width: 100%;
    text-align: left;
    font-family: 'SF Pro Display', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 130%;
    color: #92009D;
}

.calendar-button {
    background: none;
    border: none;
    line-height: 60px;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    color: #1F3C75;
}

.calendar-container {
    width: 30%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.FormInput {
    display: flex;
    width: 100%;
    height: 60px;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 75px 75px rgba(69, 42, 124, 0.15);
    backdrop-filter: blur(75px);
    border-radius: 15px;
    margin-bottom: 10%;
}

.FormInputText {
    align-items: center;
    justify-content: left;
    text-align: left;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #1F3C75;
    padding-left: 5%;
    padding-right: 5%;
    width: 100%;
    background: none;
    border: none;
}

table {
    font-family: 'Montserrat';
    font-style: normal;
    color: #1F3C75;
    width: 50%;
}

.left-table {
    border-right: 10px;
    border-color: #1F3C75;
}

tbody {
    font-weight: 700;
    text-align: left;
}

.Tables {
    display: flex;
    width: 100%;
    padding: 5%;
}

.HistoryTable {
    font-size: 20px;
}

.PanelTable {
    font-weight: bold;
    font-size: 10px;
}

.Panel {
    justify-content: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    color: #1F3C75;
    border: none;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 75px 75px rgba(69, 42, 124, 0.15);
    backdrop-filter: blur(75px);
    margin-bottom: 10%;
    width: 100%;
    border-radius: 30px;
    padding: 10px;
}

.PanelTable {
    width: 100%;
    display: flex;
}

.PanelTableTitle {
    width: 50%;
    padding-top: 5%;
    padding-left: 5%;
    text-align: left;
}

.loader {
    margin-top: 20%;
    margin-left: auto;
    margin-right: auto;
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #1F3C75; /* Blue */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

button {
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #1F3C75;
}

.Button-extended-add {
    width: 20%;
    height: 100%;

    image {
        width: 48px;
        height: 48px;
    }
}
